import Header from "./Header";
import "./App.css";
import Section from "./Section";

import Title from "./Title";

import Slider from "./Carousel";

import Infohome from "./Infohome";
import Hotmart from "./Hotmart";

function App() {
  return (
    <div>
      <Header />
      <Title />
      <Section />
      <Slider />
      <Hotmart />
      <Infohome />
    </div>
  );
}

export default App;
