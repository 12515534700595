import hot from "./images/hot.mp4";

export default function Hotmart() {
  return (
    <div>
      <div className="gifdivh">
        <a
          target="_blank"
          href="https://eccsgdocc.hotmart.host/pagina-de-vendas-613e2c0b-1335-47cf-b178-8528aefac8ea"
        >
          <video
            className="video"
            controls={false}
            autoPlay={true}
            loop={true}
            playsInline
            muted
          >
            <source src={hot} />
          </video>
        </a>
      </div>
    </div>
  );
}
