import { Link } from "react-router-dom";
export default function Btns() {
  return (
    <div className="divbtns">
      {" "}
      <Link to="/serviços">
        <button className="btnsection">QUERO CONHECER A ECCS</button>
      </Link>
    </div>
  );
}
