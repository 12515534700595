import Carouselpage from "../Carouselpage";
import Header from "../Header";
import Hotmart from "../Hotmart";
import Info from "../Info";

import wpp from "../images/wpp.png";

export default function Eccs() {
  return (
    <div>
      <Header />

      <div className="eccsdiv">
        <div className="divsub">
          {" "}
          <h2 className="subtext">
            Soluções inteligentes e gerenciamento eficiente de informações.
          </h2>
        </div>
        <div className="diveccslogo"> </div>

        <Carouselpage />

        <div className="divsubtitle">
          <h3 className="subtitle">
            Oferecemos ferramentas e recursos que facilitará a organizar e
            localizar seus documentos de forma eficiente.
          </h3>
        </div>

        <div className="divorcamento">
          <h2 className="orcamentotext">SOLICITE SEU ORÇAMENTO!</h2>
          <a target="_blank" href="https://wa.me/31992479224">
            <button className="btnorcamento">
              ORÇAMENTO <img src={wpp} className="wpp" />
            </button>
          </a>
        </div>
      </div>
      <Hotmart />

      <Info />
    </div>
  );
}
