import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Eccs from "../RoutesMenu/Eccs";
import App from "../App";
import Servico from "../RoutesMenu/Servico";
import Contato from "../RoutesMenu/Contato";

const Routering = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/eccs" element={<Eccs />} />
        <Route path="/serviços" element={<Servico />} />
        <Route path="/contato" element={<Contato />} />
      </Routes>
    </Router>
  );
};
export default Routering;
