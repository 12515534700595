import eccslogo from "./images/eccslogo.png";
import { Link } from "react-router-dom";
import React from "react";

import { useMemo, useState, useEffect } from "react";

export default function Header() {
  const [sticky, setSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 65);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const [currentPage] = useState(0);
  useMemo(() => {
    window.scrollTo({ top: 0 });
  }, [currentPage]);
  return (
    <nav className={`${sticky ? "sticky" : ""}`}>
      <div className="headerdiv">
        <div className="divlogo">
          <img className="logo" src={eccslogo}></img>
        </div>
        <div className="divlinks">
          <Link className="link" to="/">
            <h2 className="links">HOME</h2>
          </Link>
          <Link className="link" to="/eccs">
            <h2 className="links">ECCS</h2>
          </Link>
          <Link className="link" to="/serviços">
            <h2 className="links"> SERVIÇOS</h2>
          </Link>
          <Link className="link" to="/contato">
            <h2 className="links">CONTATO</h2>
          </Link>
        </div>
      </div>
    </nav>
  );
}
