import Btnheader from "./Btnheader";
import eccs from "./images/eccs.mp4";
export default function Title() {
  return (
    <div className="divtitle">
      <div>
        <div className="gifdiv">
          <video
            className="video"
            controls={false}
            autoPlay={true}
            loop={true}
            playsInline
            muted
          >
            <source src={eccs} />
          </video>
        </div>
      </div>

      <div className="sobretitle">
        <h1 className="titleeccs">
          Bem-vindo ao site da ECCS, sua solução completa para gestão de
          documentos!
        </h1>
      </div>
      <Btnheader />

      <div className="divgifh"></div>
    </div>
  );
}
