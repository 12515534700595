import { AiOutlineLinkedin, AiOutlineInstagram } from "react-icons/ai";
import wpp from "./images/wpp.png";

export default function Info() {
  return (
    <div className="infoblue">
      <div className="infos">
        <div className="divsocial">
          REDES SOCIAIS
          <h3 className="h3social">
            <a
              className="a"
              target="_blank"
              href="https://www.instagram.com/eccs_gdoc/"
            >
              <AiOutlineInstagram className="socials" />
              <h3 className="namesocial"> </h3>
            </a>
            <a
              className="a"
              href="https://www.linkedin.com/in/eccs-gest%C3%A3o-de-documentos-consultoria-em-gest%C3%A3o-de-documentos-ab0475282/"
              target="_blank"
            >
              <AiOutlineLinkedin className="socials" />
              <h3 className="namesocial"></h3>
            </a>
          </h3>
        </div>
        <div className="divsocial">
          PRECISA DE AJUDA?
          <a target="_blank" href="https://wa.me/31992479224">
            <button className="infowpp">
              FALE CONOSCO
              <img src={wpp} className="wpp" />
            </button>
          </a>
        </div>
      </div>

      <div className="direitos">© 2024 ECCS.</div>
    </div>
  );
}
