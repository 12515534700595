import wpp from "./images/wpp.png";
export default function Btnheader() {
  return (
    <div className="divheaderbtn">
      PRECISA DE UM GESTOR?
      <a target="_blank" href="https://wa.me/31992479224">
        <button className="btnheader">
          FALE CONOSCO
          <img src={wpp} className="wpp" />
        </button>
      </a>
    </div>
  );
}
