import t from "./images/t.png";
import q from "./images/q.png";
import c from "./images/c.png";
import s from "./images/s.png";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";

import Btns from "./Btns";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Slider = () => (
  <div>
    <div className="carouseltitle">
      <h2 className="textcarousel">
        Na ECCS Gestão de Documentos, tornamos essa visão uma realidade para
        você.
      </h2>
    </div>
    <Btns />

    <div className="divfullc">
      <AutoplaySlider play={true} cancelOnInteraction={false} interval={5000}>
        <div className="divcarousel">
          <h3 className="h3carousel">
            <img src={t} className="docicon" />
          </h3>
          <h3 className="iconstext">DIGITALIZE SEUS DOCUMENTOS</h3>
        </div>
        <div className="divcarousel">
          <h3 className="h3carousel">
            <img src={q} className="docicon" />
          </h3>
          <h3 className="iconstext">ARMAZENAMENTO NA NUVEM</h3>
        </div>
        <div className="divcarousel">
          <h3 className="h3carousel">
            <img src={c} className="docicon" />
          </h3>
          <h3 className="iconstext">OTIMIZE SEU TRABALHO</h3>
        </div>
        <div className="divcarousel">
          <h3 className="h3carousel">
            <img src={s} className="docicon" />
          </h3>
          <h3 className="iconstext">RASTREABILIDADE E SEGURANÇA</h3>
        </div>
      </AutoplaySlider>
    </div>
  </div>
);
export default Slider;
