import { Link } from "react-router-dom";
import initgif from "./images/initgif.mp4";
export default function Section() {
  return (
    <div className="sectiondiv">
      <div className="divimg">
        <div className="gifdiv">
          <video
            className="video"
            controls={false}
            autoPlay={true}
            loop={true}
            playsInline
            muted
          >
            <source src={initgif} />
          </video>
        </div>
      </div>
    </div>
  );
}
