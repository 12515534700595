import AwesomeSlider from "react-awesome-slider";
import withAutoplay from "react-awesome-slider/dist/autoplay";
import "react-awesome-slider/dist/styles.css";
import tec from "./images/tec.mp4";

const AutoplaySlider = withAutoplay(AwesomeSlider);

const Carouselpage = () => (
  <div>
    <div className="gifdiv">
      <a
        target="_blank"
        href="https://eccsgdocc.hotmart.host/pagina-de-vendas-613e2c0b-1335-47cf-b178-8528aefac8ea"
      >
        <video
          className="video"
          controls={false}
          autoPlay={true}
          loop={true}
          playsInline
          muted
        >
          <source src={tec} />
        </video>
      </a>
    </div>
  </div>
);
export default Carouselpage;
