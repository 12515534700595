import Header from "../Header";
import Info from "../Info";
import { useState } from "react";
import emailjs from "@emailjs/browser";

export default function Contato() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  function sendEmail(e) {
    e.preventDefault();
    if (name === "" || email === "" || phone === "" || message === "") {
      alert("Preencha todos os campos.");
    }
    const templateParams = {
      from_name: name,
      message: message,
      email: email,
    };
    emailjs
      .send(
        "service_nru3b0y",
        "template_c7cvbfv",
        templateParams,
        "8SxRbcBdDhC-bD1uT"
      )
      .then(
        (response) => {
          console.log("EMAIL ENVIADO", response.status, response.text);
          setName("");
          setEmail("");
          setPhone("");
          setMessage("");
        },
        (err) => {
          console.log("ERRO", err);
        }
      );
  }
  return (
    <div>
      <Header />
      <div className="contatodivform">
        <h1 className="titlecontato">CONTATO</h1>
        <h2 className="subtitlecontato">
          PREENCHA NOSSO FORMULÁRIO, RETORNAMOS EM BREVE.
        </h2>
      </div>
      <form onSubmit={sendEmail} className="divform">
        <div className="divinputs">
          <h2 className="nameinputs">NOME</h2>
          <input
            className="inputs"
            placeholder="DIGITE SEU NOME"
            onChange={(e) => setName(e.target.value)}
            value={name}
          ></input>
          <hr className="hrinputs"></hr>
          <h2 className="nameinputs">E-MAIL</h2>
          <input
            className="inputs"
            placeholder="DIGITE SEU E-MAIL"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          ></input>
          <hr className="hrinputs"></hr>
          <h2 className="nameinputs">TELEFONE</h2>
          <input
            className="inputs"
            placeholder="DIGITE SEU TELEFONE"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          ></input>
          <hr className="hrinputs"></hr>
          <h2 className="nameinputs">MENSAGEM</h2>
          <input
            className="inputs"
            placeholder="DIGITE SUA MENSAGEM"
            onChange={(e) => setMessage(e.target.value)}
            value={message}
          ></input>
          <hr className="hrinputs"></hr>
          <div className="divbtn">
            <button type="submit" className="btncontato">
              ENVIAR
            </button>
          </div>
        </div>
      </form>

      <Info />
    </div>
  );
}
