import Header from "../Header";
import Hotmart from "../Hotmart";
import Info from "../Info";
import wpp from "../images/wpp.png";

export default function Servico() {
  return (
    <div>
      <Header />
      <div className="divtitleservico">
        <h2 className="servicoh2">
          Soluções práticas e inovadoras para lidar com o fluxo constante de
          informações e documentos em sua organização.
        </h2>
      </div>
      <div className="servicosection">
        <div className="divparceiros">
          <div className="contatosdiv">
            <div>
              <h2 className="contatotitle">DIGITALIZAÇÃO</h2>
            </div>
            <h2 className="subtitleparceiros1">
              Possuímos tecnologia de ponta para digitalização de documentos.
              Transformamos registros físicos em arquivos digitais acessíveis,
              facilitando o compartilhamento.
            </h2>
          </div>
          <div className="produtosdiv">
            <div>
              <h2 className="produtostitle">ORGANIZAÇÃO</h2>
            </div>
            <h2 className="subtitleparceiros">
              Nossa solução de GED é uma ferramenta poderosa para organizar
              documentos digitais com eficiência. Você poderá localizar e
              recuperar informações rapidamente.
            </h2>
          </div>
        </div>
      </div>
      <div className="servicosection">
        <div className="divparceiros">
          <div className="cadastrodiv">
            <div>
              <h2 className="cadastrotitle">CONSULTORIA</h2>
            </div>
            <h2 className="subtitleparceiroscadastro">
              A eficiência em gestão de documentos é fundamental para o sucesso
              empresarial. Nossa consultoria especializada oferece soluções para
              ajudar sua organização.
            </h2>
          </div>
          <div className="entregadiv">
            <div>
              <h2 className="entregatitle">FACILIDADE</h2>
            </div>
            <h2 className="subtitleparceirosentrega">
              Oferemos serviços de organização e indexação de documentos,
              tornando o acesso e a busca por informações específicas uma tarefa
              de alta facilidade.
            </h2>
          </div>
        </div>
      </div>
      <div className="servicosection">
        <div className="divparceiros">
          <div className="acompanhamentodiv">
            <div>
              <h2 className="acompanhartitle">OTIMIZAÇÃO</h2>
            </div>
            <h2 className="subtitleparceiros1">
              Nossos especialistas em gestão de documentos estão prontos para
              ajudá-lo(a). Identificamos oportunidades de melhoria e
              implementamos soluções eficientes.
            </h2>
          </div>
          <div className="servicodiv">
            <div>
              <h2 className="servicotitle">SEGURANÇA</h2>
            </div>
            <h2 className="subtitleparceiros">
              Nosso compromisso é proporcionar a você e sua empresa, soluções
              confiáveis e inovadoras para lidar com a complexidade da gestão
              documental diária.
            </h2>
          </div>
        </div>
      </div>

      <div className="divorcamentos">
        <h2 className="orcamentotext">SOLICITE SEU ORÇAMENTO!</h2>
        <a target="_blank" href="https://wa.me/31992479224">
          <button className="btnorcamento">
            ORÇAMENTO <img src={wpp} className="wpp" />
          </button>
        </a>
      </div>
      <Hotmart />
      <Info />
    </div>
  );
}
